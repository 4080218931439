var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{ref:"validationForm",attrs:{"default-submit-buttons":false},on:{"validated":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var observer = ref.observer;
return [_c('Modal',_vm._g(_vm._b({attrs:{"scrollable":"","size":"lg","footer-class":"import-footer","title":_vm.$attrs['title'] || 'Import',"value":_vm.value,"submit-buttons":{
      save: { text: _vm.formErrors ? 'Import Again' : 'Import' },
      invalid: observer.failed,
      loading: _vm.form.isLoading
    }},on:{"save":_vm.handleSave,"hidden":_vm.resetErrors}},'Modal',_vm.$attrs,false),_vm.$listeners),[_c('b-card',{staticClass:"shadow-none",attrs:{"no-body":""}},[_c('b-card-title',[_vm._v("CSV File")]),_c('b-card-text',[_vm._v(" Download "),_c('b-link',{attrs:{"href":"#"}},[_vm._v("Import Template")]),_vm._v(". Need Help? "),_c('b-link',{attrs:{"href":"#"}},[_vm._v("Watch a video")]),_vm._v(". ")],1),(!_vm.formErrors)?_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('form-validation-file-input',{ref:"file",attrs:{"rules":"required|ext:csv","accept":".csv","validation":{
              customMessages: {
                ext: 'The file must be a .csv extension'
              }
            }},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1)],1):_c('section',[_c('b-alert',{staticClass:"mb-2",attrs:{"show":"","variant":"danger"}},[_c('h2',{staticClass:"alert-heading"},[_vm._v(" The import did not go through due to errors found with the data. Please correct the errors below and import again. ")]),(_vm.errorWithHeading)?_c('div',{staticClass:"alert-body"},[_c('ul',_vm._l((_vm.form.error.errors),function(error,field){return _c('li',{key:field},[_vm._v(" "+_vm._s(_vm.removeSnakeCase(error[0]))+" ")])}),0)]):_vm._e()]),(!_vm.errorWithHeading)?_c('b-table',{attrs:{"sticky-header":"","outlined":"","fields":_vm.fields,"items":_vm.form.error.errors}}):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }