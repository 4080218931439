<template>
  <form-validation-field
    ref="autocomplete"
    component="vue-autosuggest"
    :value="value"
    :name="inputProps.name"
    :input-props="inputProps"
    :input-class="{ autocomplete: true, 'is-loading': isLoading }"
    :wrapper="showInputGroup ? 'b-input-group' : undefined"
    v-bind="$attrs"
    v-on="$listeners"
    @blur="handleBlur"
    @selected="handleSelected"
  >
    <!-- Loading Spinner -->
    <template
      v-if="showInputGroup"
      v-slot:append-input
    >
      <b-input-group-append
        v-show="isLoading"
        is-text
      >
        <b-spinner
          small
          variant="primary"
        />
      </b-input-group-append>
    </template>

    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :slot="slot"
      :name="slot"
    />

    <!-- Pass on all scoped slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </form-validation-field>
</template>

<script>
import { BInputGroupAppend, BSpinner } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    BInputGroupAppend,
    BSpinner,
    FormValidationField,
  },
  props: {
    value: {
      required: true,
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
    inputProps: {
      type: Object,
      default: () => ({
        name: 'autocomplete',
        class: ['form-control'],
      }),
    },
  },
  computed: {
    showInputGroup() {
      return this.isLoading !== undefined
    },
  },
  watch: {
    value() {
      this.validateInput()
    },
  },
  methods: {
    async validateInput(props = null) {
      if (!props) {
        // eslint-disable-next-line no-param-reassign
        props = await this.$refs.autocomplete.$refs.validation.validate()
      }

      // Add or remove form control invalid class.
      if (props.valid) {
        this.inputProps.class = ['form-control']
        return true
      }

      if (!this.inputProps.class.includes('is-invalid')) {
        this.inputProps.class.push('is-invalid')
      }

      return false
    },
    handleBlur() {
      this.$emit('blur')

      this.$nextTick(() => {
        this.validateInput()
      })
    },
    handleSelected(selected) {
      this.$emit('selected', selected)

      this.$nextTick(() => {
        this.validateInput()
      })
    },
  },
}
</script>

<style lang="scss">
.input-group {
  .autocomplete {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    &.is-loading {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
