<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @validated="updateTeam"
  >
    <Modal
      title="Edit Team"
      size="lg"
      scrollable
      :value="value"
      footer-class="team-footer"
      @show="handleShow"
      v-on="$listeners"
    >
      <template #modal-footer>
        <submit-buttons
          :loading="form.isLoading"
          :invalid="observer.failed"
          @save="$refs.validationForm.validate()"
          v-on="$listeners"
        >
          <template #prepend>
            <b-button
              class="mr-1"
              :to="{ name: 'team.edit', params: { team: team.id }, query: { event: event.id } }"
            >
              More Info
            </b-button>
          </template>
        </submit-buttons>
      </template>

      <!-- Season Info -->
      <b-card
        no-body
        class="shadow-none m-0 mt-2"
      >
        <b-card-title>Season Info</b-card-title>
        <b-row>
          <!-- Season -->
          <b-col sm="6">
            <select-season
              v-model="form.season_id"
              label="Season*"
              disabled
            />
          </b-col>

          <!-- Division -->
          <b-col sm="6">
            <select-division
              v-model="form.division_id"
              rules="is_not:Select"
              mode="lazy"
              :division.sync="division"
            />
          </b-col>
        </b-row>
      </b-card>

      <hr
        role="separator"
        aria-orientation="horizontal"
        class="dropdown-divider"
      >

      <!-- Team Info -->
      <b-card
        no-body
        class="shadow-none mb-0 mt-2"
      >
        <b-card-title>Team Info</b-card-title>
        <form-team-info :team="form" />
      </b-card>

    </Modal>
  </validation-form>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BRow, BButton,
} from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import Modal from '@/components/modals/Modal.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'
import SelectSeason from '@/components/forms/selects/SelectSeason.vue'
import SelectDivision from '@/components/forms/selects/SelectDivision.vue'
import FormTeamInfo from '@/components/forms/FormTeamInfo.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    ValidationForm,
    Modal,
    SubmitButtons,
    SelectSeason,
    SelectDivision,
    FormTeamInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    team: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      division: null,
      form: new Form({
        division_id: null,
        name: '',
        abbreviation: '',
        logo: undefined,
        logo_url: '',
        level: null,
        age: null,
        grade: null,
      }, true),
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    handleShow() {
      this.division = this.team.division

      Object.assign(this.form, {
        ...this.team,
        division_id: this.team.division?.id || null,
      })
    },
    updateTeam() {
      this.form.post(`/teams/${this.team.id}?season_id=${this.season.id}`, false, true)
        .then(response => {
          const { data } = response

          this.$emit('update-team', {
            ...this.team,
            ...data,
            division: this.division,
          })
        })
    },
  },
}
</script>

<style lang="scss">
.team-footer {
  //display: block;
}
</style>
