<template>
  <div>
    <b-button
      v-if="isFiltered"
      variant="link"
      :size="$store.getters['app/currentBreakPoint'] === 'xs' ? 'sm' : 'md'"
      @click="handleReset"
    >
      Reset
    </b-button>

    <b-button
      :variant="active ? 'primary' : 'outline-primary'"
      class="btn-icon"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <font-awesome-icon
        icon="fa-light fa-filter"
        size="lg"
        class="mr-sm-50"
      />
      <span class="d-none d-sm-inline align-middle">Filters</span>
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { cloneObject } from '@/helpers/objects'

export default {
  components: {
    BButton,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    filters: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isFiltered: false,
      originalFilters: {},
    }
  },
  watch: {
    filters: {
      handler() {
        this.isFiltered = JSON.stringify(this.originalFilters) !== JSON.stringify(this.filters)
      },
      deep: true,
    },
  },
  created() {
    this.originalFilters = cloneObject(this.filters)
  },
  methods: {
    handleReset() {
      this.$emit('reset', cloneObject(this.originalFilters))
      this.isFiltered = false
    },
  },
}
</script>

<style scoped>

</style>
