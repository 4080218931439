<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @validated="submit"
  >
    <Modal
      scrollable
      size="lg"
      footer-class="import-footer"
      :title="$attrs['title'] || 'Import'"
      :value="value"
      :submit-buttons="{
        save: { text: formErrors ? 'Import Again' : 'Import' },
        invalid: observer.failed,
        loading: form.isLoading
      }"
      v-bind="$attrs"
      @save="handleSave"
      @hidden="resetErrors"
      v-on="$listeners"
    >
      <b-card
        no-body
        class="shadow-none"
      >
        <b-card-title>CSV File</b-card-title>

        <!-- eslint-disable -->
        <b-card-text>
          Download <b-link href="#">Import Template</b-link>. Need Help? <b-link href="#">Watch a video</b-link>.
        </b-card-text>
        <!-- eslint-enable -->

        <b-row v-if="!formErrors">
          <b-col cols="auto">
            <form-validation-file-input
              ref="file"
              v-model="form.file"
              rules="required|ext:csv"
              accept=".csv"
              :validation="{
                customMessages: {
                  ext: 'The file must be a .csv extension'
                }
              }"
            />
          </b-col>
        </b-row>

        <!-- Errors -->
        <section v-else>
          <b-alert
            show
            variant="danger"
            class="mb-2"
          >
            <h2 class="alert-heading">
              The import did not go through due to errors found with the data. Please correct the errors below and import again.
            </h2>

            <div
              v-if="errorWithHeading"
              class="alert-body"
            >
              <ul>
                <li
                  v-for="(error, field) in form.error.errors"
                  :key="field"
                >
                  <!-- Display the first error message for each field -->
                  {{ removeSnakeCase(error[0]) }}
                </li>
              </ul>
            </div>
          </b-alert>

          <b-table
            v-if="!errorWithHeading"
            sticky-header
            outlined
            :fields="fields"
            :items="form.error.errors"
          />
        </section>
      </b-card>
    </Modal>
  </validation-form>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BLink, BRow, BCol, BTable, BAlert,
} from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationFileInput from '@/components/forms/validation/FormValidationFileInput.vue'

import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    BAlert,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BRow,
    BCol,
    BTable,
    Modal,
    ValidationForm,
    FormValidationFileInput,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    endPoint: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      form: new Form({
        file: null,
      }, true),
      fields: [
        'row',
        {
          key: 'attribute',
          label: 'column',
          formatter: value => this.removeSnakeCase(value),
        },
        {
          key: 'errors',
          formatter: value => this.removeSnakeCase(value.join('& ')),
        },
        {
          key: 'values',
          label: 'data',
          formatter: (value, key, item) => value[item.attribute],
        },
      ],
    }
  },
  computed: {
    formErrors() {
      return this.form.error.errors
    },
    errorWithHeading() {
      return typeof this.form.error.errors === 'object' && Object.keys(this.form.error.errors).some(key => key.includes('heading'))
    },
  },
  methods: {
    removeSnakeCase(value) {
      return value.replace(/_/g, ' ')
    },
    handleSave() {
      if (!this.formErrors) {
        this.$refs.validationForm.validate()
      }

      this.resetErrors()
    },
    resetErrors() {
      this.form.error.errors = false
    },
    submit() {
      this.form.flashMessages = false

      this.form.post(this.endPoint)
        .then(() => {
          this.$emit('import-successful')
        })
        .catch(error => {
          // Don't show 422 messages because the output will be in the error section so the user can make the necessary changes.
          if (error.status !== 422) {
            flashError(error)
          }

          this.$emit('import-failed')
        })
    },
  },
}
</script>

<style scoped>

</style>
