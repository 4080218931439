<template>
  <section v-if="season">
    <!-- Add New Team -->
    <add-team
      v-if="addTeam"
      :teams="teams"
      @add-team="handleAddTeam"
      @cancel="addTeam = false"
    />

    <!-- Edit Team -->
    <edit-team
      v-model="editTeam"
      :team="team"
      @update-team="handleUpdateTeam"
      @cancel="editTeam = false"
    />

    <b-card no-body>
      <card-header-filters
        title="Teams"
        :button="{ show: !addTeam }"
        :import-button="true"
        :filters="filters"
        @add="addTeam = true"
        @import="importTeams = true"
        @reset-filters="filters = $event"
      >
        <template #filters>
          <!-- Division Filter -->
          <select-division
            v-model="filters.division"
            is-filter
          />
        </template>
      </card-header-filters>

      <!-- Teams List -->
      <table-list
        primary-key="id"
        :items="filteredTeams"
        :fields="fields"
        :endpoints="{ delete: `/seasons/${season.id}/teams` }"
        :busy="isLoading"
        item-name="team"
        delete-button-text="Remove from Season"
        :delete-modal="({ item }) => ({
          title: 'Remove Team From Season',
          text: `Are you sure you want to remove this team from the <b>${season.name}</b> season?`,
          flashMessage: `${item.name} has been successfully removed!`,
          afterDelete: () => handleDeleteTeam(item)
        })"
      >
        <!-- Name cell -->
        <template #cell(name)="{ item }">
          <b-link @click.prevent="showEditTeam(item)">
            <b-img
              v-if="item.logo_url"
              :src="item.logo_url"
              class="team-logo mr-1"
              rounded
            />
            {{ item.name }}
          </b-link>
        </template>

        <!-- Record cell -->
        <template #cell(record)="{ item }">
          {{ item.record.wins }} - {{ item.record.losses }}<template v-if="item.record.ties">
            - {{ item.record.ties }}
          </template>
        </template>
      </table-list>
    </b-card>

    <modal-import-teams
      v-model="importTeams"
      title="Import Teams"
      :end-point="`seasons/${this.$store.state.event.seasons.season.id}/teams/import`"
      @cancel="importTeams = false"
      @import-successful="handleImportSuccessful"
    />

  </section>
</template>

<script>
import {
  BCard, BImg, BLink,
} from 'bootstrap-vue'
import TableList from '@/components/TableList.vue'
import AddTeam from '@/views/events/manage/teams/EventManageTeamAdd.vue'
import EditTeam from '@/views/events/manage/teams/EventManageTeamEdit.vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import SelectDivision from '@/components/forms/selects/SelectDivision.vue'
import ModalImportTeams from '@/components/modals/ModalImport.vue'

import { mapGetters, mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventTeams',
  components: {
    BCard,
    BImg,
    BLink,
    TableList,
    AddTeam,
    EditTeam,
    CardHeaderFilters,
    SelectDivision,
    ModalImportTeams,
  },
  data() {
    return {
      isLoading: true,
      addTeam: false,
      editTeam: false,
      team: {},
      importTeams: false,
      fields: [
        'name',
        {
          key: 'record',
          formatter: (value, key, item) => item.record,
        },
        {
          key: 'division',
          formatter: (value, key, item) => (item.division?.id ? item.division.name : ''),
        },
        {
          key: 'rostered_players',
          label: 'players',
        },
        'level',
        'grade',
      ],
      filters: {
        division: 'All',
      },
    }
  },
  computed: {
    ...mapState('event/seasons', ['season']),
    ...mapState('event/teams', ['teams']),
    ...mapGetters('event/teams', ['teamsInDivision']),
    filteredTeams() {
      return this.teamsInDivision(this.filters.division)
    },
  },
  watch: {
    season: {
      immediate: true,
      async handler(season) {
        if (!season) {
          return
        }

        await this.getTeams()
      },
    },
  },
  methods: {
    showEditTeam(team) {
      this.team = team
      this.editTeam = true
    },
    handleImportSuccessful() {
      this.importTeams = false
      this.getTeams()
    },
    handleAddTeam(team) {
      Object.assign(team, {
        record: {
          wins: 0,
          losses: 0,
          ties: 0,
        },
        rostered_players: 0,
      })

      this.teams.push(team)
      this.sortTeams()
    },
    handleUpdateTeam(team) {
      this.teams.splice(
        this.teams.findIndex(t => t.id === team.id),
        1,
        team,
      )

      this.sortTeams()
      this.editTeam = false
    },
    handleDeleteTeam(team) {
      this.teams.splice(this.teams.findIndex(t => t.id === team.id), 1)
    },
    sortTeams() {
      // Resort teams alphabetically.
      this.teams.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })
    },
    async getTeams() {
      this.isLoading = true

      try {
        await this.$store.dispatch('event/teams/getTeams', {
          query: `season_id=${this.$store.state.event.seasons.season.id}&roster_count=true`,
        })
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.isLoading = false
      this.$Progress.finish()
    },
  },
}
</script>

<style lang="scss" scoped>
.team-logo {
  max-width: 30px;
  max-height: 30px;
}

// Set table column widths.
colgroup {
  .name {
    min-width: 225px;
    max-width: 300px;
  }
  .rostered_players {
    min-width: 150px;
    max-width: 200px;
  }
  .delete {
    width: 200px;
  }
}
</style>
