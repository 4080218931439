<template>
  <b-form-group
    label="Level"
    label-for="level"
  >
    <b-form-select
      name="level"
      :options="levels"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      levels: [
        { text: '-', value: null },
        { text: 'Adult', value: 'adult' },
        { text: 'Youth', value: 'youth' },
        { text: 'Middle School', value: 'middle school' },
        { text: 'High School', value: 'high school' },
        'AAU',
        { text: 'College', value: 'college' },
        { text: 'Semi-Pro', value: 'semi-pro' },
        { text: 'Pro', value: 'pro' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
