<template>
  <component
    :is="component"
    ref="cardModal"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      name="default"
      :is-card="isCard"
      :is-modal="!isCard"
    />

    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots).filter(s => s !== 'default')"
      :slot="slot"
      :name="slot"
    />
  </component>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'

export default {
  components: {
    BCard,
    Modal,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    card: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    component() {
      return this.$store.getters['app/currentBreakPoint'] === 'xs' || this.card === false ? 'Modal' : 'BCard'
    },
    isCard() {
      return this.component === 'BCard'
    },
  },
}
</script>

<style scoped>

</style>
