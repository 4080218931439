<template>
  <form-validation-field
    :vid="$attrs['vid'] || 'team_id'"
    :name="$attrs['name'] || 'team'"
    component="BFormSelect"
    value-field="id"
    text-field="name"
    :label="$attrs['label'] || label"
    :options="options"
    :value="value"
    :disabled="$attrs['disabled'] || disabled"
    v-bind="$attrs"
    @change="$emit('change', $event)"
    @input="handleInput"
  >
    <!-- Pass on all named slots -->
    <slot
      v-for="slot in Object.keys($slots)"
      :slot="slot"
      :name="slot"
    />
  </form-validation-field>
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

import { mapState, mapGetters } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    includeFreeAgent: {
      type: Boolean,
      default: true,
    },
    filterByDivision: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapState('event/seasons', ['season']),
    ...mapState('event/teams', ['teams']),
    ...mapGetters('event/teams', ['teamsInDivision']),
    label() {
      const label = 'Team'

      return this.$attrs?.rules && this.$attrs.rules.includes('is_not:Select') ? `${label}*` : label
    },
    options() {
      if (this.$attrs.options) {
        return this.$attrs.options
      }

      const defaultOption = this.isFilter
        ? { id: 'All', name: 'All' }
        : { id: 'Select', name: 'Select Team', disabled: true }

      let options = this.filterByDivision !== undefined
        ? this.teamsInDivision(this.filterByDivision)
        : this.teams

      // Add Free Agent option.
      if (this.includeFreeAgent) {
        options = [
          { id: null, name: 'Free Agent' },
          ...options,
        ]
      }

      return [
        defaultOption,
        ...options,
      ]
    },
  },
  watch: {
    filterByDivision() {
      this.$emit('input', 'All')
    },
  },
  mounted() {
    this.$watch('season', async season => {
      if (!season) {
        return
      }

      // Clear input.
      this.disabled = true

      // Avoid double loading progress bar.
      if (!this.$Progress.get()) {
        this.$Progress.start()
      }

      try {
        await this.$store.dispatch('event/teams/getTeams', { query: `season_id=${season.id}` })

        this.$Progress.finish()
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.disabled = false
    }, { immediate: !this.teams.length })
  },
  methods: {
    handleInput(id) {
      this.$emit('input', id)
      this.$emit(
        'update:team',
        id ? this.options.find(team => team.id === id) : null,
      )
    },
  },
}
</script>

<style scoped>

</style>
