var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.season)?_c('section',[(_vm.addTeam)?_c('add-team',{attrs:{"teams":_vm.teams},on:{"add-team":_vm.handleAddTeam,"cancel":function($event){_vm.addTeam = false}}}):_vm._e(),_c('edit-team',{attrs:{"team":_vm.team},on:{"update-team":_vm.handleUpdateTeam,"cancel":function($event){_vm.editTeam = false}},model:{value:(_vm.editTeam),callback:function ($$v) {_vm.editTeam=$$v},expression:"editTeam"}}),_c('b-card',{attrs:{"no-body":""}},[_c('card-header-filters',{attrs:{"title":"Teams","button":{ show: !_vm.addTeam },"import-button":true,"filters":_vm.filters},on:{"add":function($event){_vm.addTeam = true},"import":function($event){_vm.importTeams = true},"reset-filters":function($event){_vm.filters = $event}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('select-division',{attrs:{"is-filter":""},model:{value:(_vm.filters.division),callback:function ($$v) {_vm.$set(_vm.filters, "division", $$v)},expression:"filters.division"}})]},proxy:true}],null,false,1781952736)}),_c('table-list',{attrs:{"primary-key":"id","items":_vm.filteredTeams,"fields":_vm.fields,"endpoints":{ delete: ("/seasons/" + (_vm.season.id) + "/teams") },"busy":_vm.isLoading,"item-name":"team","delete-button-text":"Remove from Season","delete-modal":function (ref) {
      var item = ref.item;

      return ({
        title: 'Remove Team From Season',
        text: ("Are you sure you want to remove this team from the <b>" + (_vm.season.name) + "</b> season?"),
        flashMessage: ((item.name) + " has been successfully removed!"),
        afterDelete: function () { return _vm.handleDeleteTeam(item); }
      });
}},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
      var item = ref.item;
return [_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.showEditTeam(item)}}},[(item.logo_url)?_c('b-img',{staticClass:"team-logo mr-1",attrs:{"src":item.logo_url,"rounded":""}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"cell(record)",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.record.wins)+" - "+_vm._s(item.record.losses)),(item.record.ties)?[_vm._v(" - "+_vm._s(item.record.ties)+" ")]:_vm._e()]}}],null,false,995099728)})],1),_c('modal-import-teams',{attrs:{"title":"Import Teams","end-point":("seasons/" + (this.$store.state.event.seasons.season.id) + "/teams/import")},on:{"cancel":function($event){_vm.importTeams = false},"import-successful":_vm.handleImportSuccessful},model:{value:(_vm.importTeams),callback:function ($$v) {_vm.importTeams=$$v},expression:"importTeams"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }