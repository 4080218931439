<template>
  <b-row class="filters">
    <slot name="filter-wrapper">
      <slot name="prepend" />

      <b-col
        v-for="(filter, i) in $slots.default"
        :key="i"
        sm="3"
        lg="2"
      >
        <filters :filter="filter" />
      </b-col>

      <slot name="append" />
    </slot>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    filters: {
      functional: true,
      render: (createElement, context) => {
        context.props.filter.data.attrs = {
          ...context.props.filter.data.attrs,
          // Add any additional props here.
        }

        return context.props.filter
      },
    },
  },
}
</script>

<style scoped>

</style>
