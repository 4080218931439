<template>
  <section>
    <modal-card title="Add Team">
      <template #default="{ isCard }">
        <validation-form
          ref="validationForm"
          v-slot="{ observer }"
          @validated="checkForDuplicateTeams"
        >
          <b-form-row>
            <!-- Team Name -->
            <b-col
              sm="4"
              md="3"
            >
              <form-validation-auto-complete
                ref="name"
                v-model="form.name"
                :name="inputProps.name"
                :input-props="inputProps"
                :suggestions="suggestions"
                :label="inputProps.placeholder + '*'"
                :rules="'required|not_free_agent'"
                :is-loading="loadingSuggestions && !modalDuplicatedTeams"
                @input="handleTeamNameInput"
                @selected="handleSelectedTeam"
              >
                <template v-slot="{suggestion}">
                  {{ suggestion.item.name }}
                </template>
              </form-validation-auto-complete>
            </b-col>

            <!-- Division -->
            <b-col
              sm="4"
              md="2"
            >
              <select-division
                v-model="form.division_id"
                rules="is_not:Select"
                :division.sync="division"
              />
            </b-col>

            <!-- Level -->
            <b-col
              sm="2"
              lg="1"
            >
              <select-level v-model="form.level" />
            </b-col>

            <!-- Grade -->
            <b-col
              sm="2"
              lg="1"
            >
              <select-grade v-model="form.grade" />
            </b-col>

            <portal
              to="modal-footer-add-team"
              :disabled="isCard"
            >
              <submit-buttons
                class="mt-sm-2 ml-sm-75"
                :loading="form.isLoading"
                :invalid="observer.failed || debounceWaiting"
                v-on="$listeners"
                @save="$refs.validationForm.validate()"
              />
            </portal>
          </b-form-row>
        </validation-form>
      </template>

      <template #modal-footer>
        <portal-target name="modal-footer-add-team" />
      </template>
    </modal-card>

    <!-- Duplicate Team Modal -->
    <Modal
      v-model="modalDuplicatedTeams"
      title="Duplicate Teams Found"
      :submit-buttons="{ loading: form.isLoading }"
      :details="{
        title: form.name,
        text: 'A team with the same name already exists. Select an existing team from the list or press cancel to create a new team.'
      }"
      @save="handleDuplicateTeamSave"
      @cancel="handleDuplicateTeamCancel"
    >
      <!-- Team Suggestions -->
      <select-team
        input-class="mb-2"
        label="Select Team"
        :select-size="4"
        :options="suggestions.length ? suggestions[0].data : []"
        @update:team="selected = { item: $event }"
      />

      <!-- Examples -->
      <div class="font-small-3">
        <b>Tip:</b> If you would like to create another team with the same name for a different age group or level, just add an extension to the name. Here are some examples:
        <span class="d-inline-block text-black-50">
          {{ teamWithExtensions }}
        </span>
      </div>
    </Modal>

  </section>
</template>

<script>
import { BFormRow, BCol } from 'bootstrap-vue'
import ModalCard from '@/components/modals/ModalCard.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationAutoComplete from '@/components/forms/validation/FormValidationAutoComplete.vue'
import SelectDivision from '@/components/forms/selects/SelectDivision.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'
import SelectGrade from '@/components/forms/selects/SelectGrade.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectLevel from '@/components/forms/selects/SelectLevel.vue'
import Modal from '@/components/modals/Modal.vue'

import { mapState } from 'vuex'
import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    BFormRow,
    BCol,
    ModalCard,
    ValidationForm,
    FormValidationAutoComplete,
    SelectDivision,
    SubmitButtons,
    SelectTeam,
    SelectLevel,
    SelectGrade,
    Modal,
  },
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      division: null,
      form: new Form({
        name: '',
        division_id: 'Select',
        grade: null,
        level: null,
        sport: null,
        gender: null,
      }),
      inputProps: {
        id: 'name',
        placeholder: 'Team Name',
        class: ['form-control'],
        name: 'team-name',
      },
      modalDuplicatedTeams: false,
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      suggestions: [],
      loadingSuggestions: false,
      debounceWaiting: false,
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
    teamWithExtensions() {
      return ['10', '10U', 'Varsity', 'Gold', '50+', 'Pro']
        .map(extension => `${this.form.name} ${extension}`)
        .join(', ')
    },
    duplicatedTeams() {
      if (!this.suggestions.length) {
        return []
      }

      return this.suggestions[0].data
        .filter(team => team.name.toLowerCase().trim() === this.form.name.toLowerCase().trim())
    },
  },
  mounted() {
    this.form.sport = this.event.sport
    this.form.gender = this.event.gender
    this.form.keepDataWhenResetting = ['sport', 'gender', 'division_id']
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
    this.focusTeamNameInput()
  },
  methods: {
    focusTeamNameInput() {
      document.getElementById('name').focus()
    },
    clearSuggestions() {
      this.suggestions = []
      this.selected = null
    },
    searchForTeams() {
      this.loadingSuggestions = true

      this.$http.get(`/teams?organization_id=${this.event.organization_id}&sport=${this.event.sport}&gender=${this.event.gender}&name=${this.form.name}`)
        .then(response => {
          this.clearSuggestions()
          this.suggestions.push({ data: response.data.data })
        })
        .catch(error => {
          flashError(error)
        })
        .finally(() => {
          this.loadingSuggestions = false
          this.debounceWaiting = false
        })
    },
    handleTeamNameInput() {
      // This is so user cannot hit the save button before loading suggestions and checking for duplicates.
      this.debounceWaiting = true

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (!this.form.name) {
          this.clearSuggestions()
          return
        }

        this.searchForTeams()
      }, this.debounceMilliseconds)
    },
    handleSelectedTeam(selected) {
      // Prevent from running when user hits the "enter" key.
      if (!selected) {
        return
      }

      this.selected = selected

      const { name, grade, level } = selected.item

      Object.assign(this.form, { name, grade, level })
    },
    handleDuplicateTeamSave() {
      this.handleSelectedTeam(this.selected)
      this.$refs.validationForm.validate()
      this.modalDuplicatedTeams = false
    },
    handleDuplicateTeamCancel() {
      this.modalDuplicatedTeams = false
      this.selected = null
    },
    checkForDuplicateTeams() {
      if (this.debounceWaiting) {
        return
      }

      if (!this.selected && this.duplicatedTeams.length) {
        this.modalDuplicatedTeams = true
        return
      }

      // Check if selected team is already added to season.
      if (this.selected && this.teams.filter(team => team.name === this.form.name).length) {
        this.$refs.name.$refs.autocomplete.$refs.validation.setErrors(['This team is already added to this season.'])
        this.$refs.name.validateInput({ valid: false })
        return
      }

      this.addTeam()
    },
    addTeam() {
      let url = '/teams'
      let method = 'post'

      // Check if new or existing team.
      if (this.selected) {
        url = `/teams/${this.selected.item.id}`
        method = 'put'
      }

      this.form[method](`${url}?season_id=${this.season.id}&organization_id=${this.event.organization_id}`, true)
        .then(response => {
          this.$emit('add-team', {
            ...response.data,
            division: this.division.id ? this.division : null,
          })

          // Reset form.
          this.selected = null
          this.focusTeamNameInput()
        })
    },
  },
}
</script>
