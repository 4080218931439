<template>
  <section>
    <!-- Logo -->
    <image-input
      :file.sync="team.logo"
      :preview-src.sync="team.logo_url"
      label="Team Logo"
    />

    <b-row class="mb-2">
      <!-- Name -->
      <b-col sm="6">
        <form-validation-field
          v-model="team.name"
          vid="name"
          name="team-name"
          label="Team Name*"
          placeholder="Team Name"
          aria-autocomplete="none"
          rules="required|not_free_agent"
        />
      </b-col>

      <!-- Abbreviation -->
      <b-col sm="6">
        <form-validation-field
          v-model="team.abbreviation"
          rules="max:8"
          vid="abbreviation"
          name="team-abbreviation"
          label="Abbreviation"
          placeholder="Abbreviation"
          aria-autocomplete="none"
        />
      </b-col>

      <!-- Level -->
      <b-col sm="4">
        <select-level v-model="team.level" />
      </b-col>

      <!-- Age -->
      <b-col sm="4">
        <select-age v-model="team.age" />
      </b-col>

      <!-- Grade -->
      <b-col sm="4">
        <select-grade v-model="team.grade" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ImageInput from '@/components/forms/images/ImageInput.vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import SelectGrade from '@/components/forms/selects/SelectGrade.vue'
import SelectAge from '@/components/forms/selects/SelectAge.vue'
import SelectLevel from '@/components/forms/selects/SelectLevel.vue'

export default {
  name: 'FormTeamInfo',
  components: {
    BRow,
    BCol,
    ImageInput,
    FormValidationField,
    SelectGrade,
    SelectAge,
    SelectLevel,
  },
  props: {
    team: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
