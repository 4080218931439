<template>
  <b-form-group
    label="Grade"
    label-for="grade"
  >
    <b-form-select
      name="grade"
      :options="grades"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

import { getNumberWithOrdinal } from '@/helpers/numbers'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    grades() {
      const grades = [
        { text: '-', value: null },
      ]

      // eslint-disable-next-line no-plusplus
      for (let i = 12; i >= 1; i--) {
        grades.push(getNumberWithOrdinal(i))
      }

      grades.push('K')

      return grades
    },
  },
}
</script>

<style scoped>

</style>
