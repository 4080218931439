<template>
  <section>
    <b-media
      no-body
      class="align-items-center"
    >
      <b-form-group
        :label="label"
        :label-for="label"
      >
        <!-- preview -->
        <b-media-aside>
          <b-img
            class="preview-image"
            rounded
            height="118"
            blank-color="#E9ECF2"
            :blank="!preview"
            :src="preview"
          />
        </b-media-aside>
      </b-form-group>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click.prevent="$refs.validationFileInput.$refs.fileInput.$el.click()"
        >
          Upload
        </b-button>

        <form-validation-file-input
          :id="label"
          ref="validationFileInput"
          hidden
          plain
          :rules="`size:2000|ext:${filesTypesString}|${fileRules}`"
          :name="label"
          :accept="filesTypesString"
          :validation="{
            customMessages: {
              ext: `Invalid file type. Allowed ${ fileTypes.join(', ') }`
            }
          }"
          @input="updateFile"
          @validate="validation = $event"
        />
        <!--/ upload button -->

        <!-- remove button -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-primary"
          size="sm"
          class="mb-75 mr-75"
          @click.prevent="handleRemove"
        >
          Remove
        </b-button>

      </b-media-body>
    </b-media>

    <b-form-invalid-feedback
      :state="validation.valid"
      class="mb-2"
    >
      {{ validation.errors[0] || '' }}
    </b-form-invalid-feedback>

    <p class="mb-2 font-small-2 text-muted">
      Ideal dimensions are {{ idealFileDimensions }}
    </p>
  </section>
</template>

<script>
import {
  BMedia, BMediaBody, BMediaAside, BImg, BFormGroup, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, getCurrentInstance, reactive } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import FormValidationFileInput from '@/components/forms/validation/FormValidationFileInput.vue'

export default {
  components: {
    FormValidationFileInput,
    BMedia,
    BMediaBody,
    BMediaAside,
    BImg,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  props: {
    previewSrc: {
      required: false,
      type: String,
      default: null,
    },
    file: {
      required: false,
      type: File,
      default: null,
    },
    label: {
      required: true,
      type: String,
    },
    fileTypes: {
      required: false,
      type: Array,
      default: () => ['.jpg', '.JPEG', '.png'],
    },
    fileRules: {
      type: String,
      default: '',
    },
    idealFileDimensions: {
      type: String,
      default: '150 X 200',
    },
  },
  computed: {
    filesTypesString() {
      return this.fileTypes.join()
    },
  },
  watch: {
    previewSrc(value) {
      this.preview = value
    },
  },
  setup(props) {
    const { emit } = getCurrentInstance()
    const validationFileInput = ref(null)
    const preview = ref(null)
    const validation = reactive({
      valid: null,
      errors: [],
    })

    preview.value = props.previewSrc

    const updateFile = value => {
      const { inputImageRenderer } = useInputImageRenderer(validationFileInput.value.$refs.fileInput, base64 => {
        preview.value = base64
      })

      emit('update:file', value)
      inputImageRenderer()
    }

    const handleRemove = () => {
      preview.value = null
      emit('update:preview-src', null)
      emit('update:file', null)
    }

    return {
      validationFileInput,
      preview,
      validation,
      updateFile,
      handleRemove,
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-image {
  max-width: 200px;
}
</style>
