<template>
  <form-validation-field
    :vid="$attrs['vid'] || 'division_id'"
    :name="$attrs['name'] || 'division'"
    component="BFormSelect"
    value-field="id"
    text-field="name"
    :label="$attrs['label'] || label"
    :options="options"
    :value="value"
    :disabled="$attrs['disabled'] || disabled"
    v-bind="$attrs"
    @change="$emit('change', $event)"
    @input="handleInput"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  components: {
    FormValidationField,
  },
  props: {
    noDivision: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    includeNoDivision: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapState('event/seasons', ['season']),
    ...mapState('event/divisions', ['divisions']),
    label() {
      const label = 'Division'

      return this.$attrs?.rules && this.$attrs.rules.includes('is_not:Select') ? `${label}*` : label
    },
    options() {
      const defaultOption = this.isFilter
        ? { id: 'All', name: 'All' }
        : { id: 'Select', name: 'Select Division', disabled: true }

      let options = this.divisions

      // Add No Division option.
      if (this.includeNoDivision) {
        options = [
          { id: null, name: 'No Division' },
          ...options,
        ]
      }

      return [
        defaultOption,
        ...options,
      ]
    },
  },
  mounted() {
    this.$watch('season', async season => {
      if (!season) {
        return
      }

      // Clear input.
      this.disabled = true

      // Avoid double loading progress bar.
      if (!this.$Progress.get()) {
        this.$Progress.start()
      }

      try {
        await this.$store.dispatch('event/divisions/getDivisions', { season: season.id })

        this.$Progress.finish()
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.disabled = false
    }, { immediate: !this.divisions.length })
  },
  methods: {
    handleInput(id) {
      this.$emit('input', id)
      this.$emit('update:division', this.options.find(division => division.id === id))
    },
  },
}
</script>

<style scoped>

</style>
