<template>
  <b-form-group
    label="Age"
    label-for="age"
  >
    <b-form-select
      name="age"
      :options="ages"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    ages() {
      const ages = [
        { text: '-', value: null },
        'Adult',
      ]

      // eslint-disable-next-line no-plusplus
      for (let i = 19; i >= 4; i--) {
        ages.push(`${i}U`)
      }

      return ages
    },
  },
}
</script>

<style scoped>

</style>
