<template>
  <validation-observer
    ref="observer"
    v-slot="observer"
  >
    <b-form
      ref="form"
      @submit.prevent
      @keydown.enter="validate(true)"
    >

      <slot :observer="observer" />

      <slot
        v-if="defaultSubmitButtons"
        name="submit-buttons"
      >
        <portal to="footer-content">
          <submit-buttons
            :invalid="observer.failed"
            v-bind="submitButtons"
            v-on="$listeners"
            @save-and-new="validate(true)"
            @save="validate(false)"
          />
        </portal>
      </slot>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

export default {
  components: {
    BForm,
    ValidationObserver,
    SubmitButtons,
  },
  props: {
    defaultSubmitButtons: {
      type: Boolean,
      default: true,
    },
    submitButtons: {
      type: Object,
      default: () => ({}),
    },
    validateUsing: {
      type: Function,
      default: null,
    },
  },
  methods: {
    async validate(addNew) {
      await this.$emit('before-validation')

      // Use custom validation.
      if (this.validateUsing) {
        this.validateUsing(addNew)
        return
      }

      // Use default validation.
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.$emit('validation-failed')
          return
        }

        this.$emit('validated', addNew)
      })
    },
  },
}
</script>

<style scoped>

</style>
