<template>
  <form-validation-field
    vid="season_id"
    name="season"
    component="BFormSelect"
    value-field="id"
    text-field="name"
    :label="label"
    :options="seasons"
    :value="value || seasonId"
    v-bind="$attrs"
    v-on="$listeners"
    @input="updateSelected"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
  },
  computed: {
    ...mapState('event/seasons', ['seasons', 'season']),
    ...mapGetters('event/seasons', ['latest', 'seasonId']),
    label() {
      const label = 'Season'

      return this.$attrs?.rules && this.$attrs.rules.includes('required') ? `${label}*` : label
    },
  },
  async created() {
    // Do not re-fetch seasons again if same event.
    if (this.seasons.length && this.season?.event_id === this.$store.state.event.event.id) {
      return
    }

    await this.getSeasons({
      event: this.$route.params.event,
    })

    this.updateSelected(this.value || this.seasonId || this.latest.id)
  },
  methods: {
    ...mapMutations('event/seasons', ['UPDATE_SEASON']),
    ...mapActions('event/seasons', ['getSeasons']),
    updateSelected(id) {
      this.UPDATE_SEASON(this.seasons.find(season => season.id === Number(id)))
      this.$emit('input', id)
    },
  },
}
</script>

<style scoped>

</style>
