<template>
  <section>
    <b-card-header class="justify-content-start">
      <slot name="header-prepend" />

      <b-card-title>
        <slot name="title">
          {{ title }}
        </slot>
      </b-card-title>

      <slot name="button">
        <b-button
          v-show="button.show"
          variant="primary"
          v-bind="button"
          class="ml-1 ml-sm-2"
          :class="{ 'btn-icon': $store.getters['app/currentBreakPoint'] === 'sm' }"
          @click="$emit('add')"
        >
          <slot name="button-content">
            <feather-icon
              icon="PlusIcon"
              class="mr-sm-50"
            />
            <span class="align-middle">{{ button.text || 'Add' }}</span>
          </slot>
        </b-button>
      </slot>

      <!-- Import Button -->
      <b-button
        v-if="showImportButton"
        variant="flat-primary"
        class="ml-1"
        @click="$emit('import')"
      >
        <font-awesome-icon icon="fa-light fa-file-import" />
        Import
      </b-button>

      <button-filter
        v-if="filters"
        ref="buttonFilter"
        :active="showFilters"
        :filters="filters"
        class="ml-auto"
        @click="showFilters = !showFilters"
        @reset="$emit('reset-filters', $event)"
      />

      <slot name="header-append" />
    </b-card-header>

    <div v-show="showFilters">
      <hr
        role="separator"
        aria-orientation="horizontal"
        class="dropdown-divider"
      >

      <filters class="my-1 mx-50">
        <slot name="filters" />
        <template #prepend>
          <slot name="filter-prepend" />
        </template>
        <template #append>
          <slot name="filter-append" />
        </template>
      </filters>
    </div>
  </section>
</template>

<script>
import { BCardHeader, BCardTitle, BButton } from 'bootstrap-vue'
import ButtonFilter from '@/components/buttons/ButtonFilter.vue'
import Filters from '@/components/filters/Filters.vue'

import { userUsedMasterPassword } from '@/auth/utils'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BButton,
    ButtonFilter,
    Filters,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({
        show: true,
        text: '',
      }),
    },
    importButton: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showFilters: false,
    }
  },
  computed: {
    showImportButton() {
      return this.importButton && userUsedMasterPassword()
    },
  },
}
</script>

<style scoped>

</style>
