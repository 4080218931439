var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal-card',{attrs:{"title":"Add Team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isCard = ref.isCard;
return [_c('validation-form',{ref:"validationForm",on:{"validated":_vm.checkForDuplicateTeams},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var observer = ref.observer;
return [_c('b-form-row',[_c('b-col',{attrs:{"sm":"4","md":"3"}},[_c('form-validation-auto-complete',{ref:"name",attrs:{"name":_vm.inputProps.name,"input-props":_vm.inputProps,"suggestions":_vm.suggestions,"label":_vm.inputProps.placeholder + '*',"rules":'required|not_free_agent',"is-loading":_vm.loadingSuggestions && !_vm.modalDuplicatedTeams},on:{"input":_vm.handleTeamNameInput,"selected":_vm.handleSelectedTeam},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_vm._v(" "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-col',{attrs:{"sm":"4","md":"2"}},[_c('select-division',{attrs:{"rules":"is_not:Select","division":_vm.division},on:{"update:division":function($event){_vm.division=$event}},model:{value:(_vm.form.division_id),callback:function ($$v) {_vm.$set(_vm.form, "division_id", $$v)},expression:"form.division_id"}})],1),_c('b-col',{attrs:{"sm":"2","lg":"1"}},[_c('select-level',{model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}})],1),_c('b-col',{attrs:{"sm":"2","lg":"1"}},[_c('select-grade',{model:{value:(_vm.form.grade),callback:function ($$v) {_vm.$set(_vm.form, "grade", $$v)},expression:"form.grade"}})],1),_c('portal',{attrs:{"to":"modal-footer-add-team","disabled":isCard}},[_c('submit-buttons',_vm._g({staticClass:"mt-sm-2 ml-sm-75",attrs:{"loading":_vm.form.isLoading,"invalid":observer.failed || _vm.debounceWaiting},on:{"save":function($event){return _vm.$refs.validationForm.validate()}}},_vm.$listeners))],1)],1)]}}],null,true)})]}},{key:"modal-footer",fn:function(){return [_c('portal-target',{attrs:{"name":"modal-footer-add-team"}})]},proxy:true}])}),_c('Modal',{attrs:{"title":"Duplicate Teams Found","submit-buttons":{ loading: _vm.form.isLoading },"details":{
      title: _vm.form.name,
      text: 'A team with the same name already exists. Select an existing team from the list or press cancel to create a new team.'
    }},on:{"save":_vm.handleDuplicateTeamSave,"cancel":_vm.handleDuplicateTeamCancel},model:{value:(_vm.modalDuplicatedTeams),callback:function ($$v) {_vm.modalDuplicatedTeams=$$v},expression:"modalDuplicatedTeams"}},[_c('select-team',{attrs:{"input-class":"mb-2","label":"Select Team","select-size":4,"options":_vm.suggestions.length ? _vm.suggestions[0].data : []},on:{"update:team":function($event){_vm.selected = { item: $event }}}}),_c('div',{staticClass:"font-small-3"},[_c('b',[_vm._v("Tip:")]),_vm._v(" If you would like to create another team with the same name for a different age group or level, just add an extension to the name. Here are some examples: "),_c('span',{staticClass:"d-inline-block text-black-50"},[_vm._v(" "+_vm._s(_vm.teamWithExtensions)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }