<template>
  <form-validation-field
    ref="formValidationField"
    name="file"
    :validation="{ detectInput: false, ...validation }"
    v-bind="$attrs"
  >
    <template #field="{ validation: { valid, validated }}">
      <b-form-file
        id="file"
        ref="fileInput"
        name="file"
        placeholder="Choose a file..."
        aria-autocomplete="none"
        :state="validated ? valid : null"
        :value="value"
        v-bind="$attrs"
        @input="handleInput"
      />
    </template>
  </form-validation-field>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    BFormFile,
    FormValidationField,
  },
  props: {
    value: {
      type: [Array, File, String],
      default: null,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async checkIfValid(e = null) {
      const validation = await this.$refs.formValidationField.$refs.validation.validate(e || this.value)

      this.$emit('validate', validation)

      return validation.valid
    },
    handleInput(e) {
      this.$emit('input', e)

      this.checkIfValid(e)
    },
  },
}
</script>

<style lang="scss">
[dir] .was-validated .custom-file-input:valid ~ .custom-file-label,
[dir] .custom-file-input.is-valid ~ .custom-file-label {
  border-color: initial;
}

[dir] .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
[dir] .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #0137CC;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
</style>
